import React, { Component } from 'react';
import '../css/App.css';

export default class Todo extends Component {
  state = {
    todo: [],
    newTodo: '',
  };

  onChangeText = (event) => {
    this.setState({
      newTodo: event.target.value,
    });
  };

  addTodo = (event) => {
    event.preventDefault();
    this.setState({
      todo: [...this.state.todo, this.state.newTodo],
      newTodo: '',
    });
  };

  removeTodo = (key) => {
    this.setState({
      todo: this.state.todo.filter((data, index) => index !== key),
    });
  };
  render() {
    const { todo, newTodo } = this.state;
    return (
      <div className="center">
        <div>
          <h1>Todo App</h1>
          <form onSubmit={this.addTodo}>
            <input type="text" value={newTodo} onChange={this.onChangeText} />
          </form>
          <ul>
            {todo.map((data, index) => (
              <li key={index}>
                {data}
                <button onClick={() => this.removeTodo(index)}>Remove</button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

import React, { PureComponent } from 'react';
import '../css/App.css';

export default class List extends PureComponent {
  state = {
    score: 5,
    data: [
      {
        id: 1,
        name: 'Abhin',
        age: 23,
      },
      {
        id: 2,
        name: 'Alex',
        age: 22,
      },
    ],
  };

  incrementScore = () => {
    this.setState({
      score: 10,
    });
  };

  render() {
    return (
      <div className="center">
        <div>
          <h2>List</h2>
          <ul>
            {this.state.data.map((value, key) => (
              <li key={value.id}>
                {value.name} ({value.age})
              </li>
            ))}
          </ul>
          <button onClick={this.incrementScore}>Score</button>
        </div>
      </div>
    );
  }
}

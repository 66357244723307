import React, { Component } from 'react';
import '../css/App.css';

export default class Forms extends Component {
  state = {
    firstName: '',
    lastName: '',
  };
  //   Catch key presses
  onChangeHandle = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  //   Submit [just log]
  onSubmit = () => console.log(this.state);
  render() {
    return (
      <div className="center">
        <form>
          <h2>Form Component</h2>
          <input
            type="text"
            name="firstName"
            value={this.state.firstName}
            onChange={this.onChangeHandle}
          />
          <input
            type="text"
            name="lastName"
            value={this.state.lastName}
            onChange={this.onChangeHandle}
          />
          <button type="button" onClick={this.onSubmit}>
            Submit
          </button>
        </form>
      </div>
    );
  }
}

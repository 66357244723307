import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from './../../img/logo-white.png';
import './header.css';

export default class Header extends Component {
  render() {
    return (
      <div>
        <header>
          <img src={logo} className="logo" alt="Logo"></img>
          <div className="nav">
            <Link to="../">{this.props.title}</Link>
            <Link to="/Counter">Counter</Link>
            <Link to="/List">List</Link>
            <Link to="/Forms">Forms</Link>
            <Link to="/About">About</Link>
          </div>
        </header>
      </div>
    );
  }
}

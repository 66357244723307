import React from 'react';
import '../../css/App.css';

export default function About() {
  return (
    <div className="center">
      <div>
        <h1>About Component</h1>
      </div>
    </div>
  );
}

import React, { Component } from 'react';
import '../css/App.css';

class Counter extends Component {
  // Methods
  state = {
    counter: 0,
  };
  onIncrement = () => {
    this.setState({
      counter: this.state.counter + 1,
    });
  };
  onDecrement = () => {
    this.setState({
      counter: this.state.counter - 1,
    });
  };
  render() {
    return (
      <div className="center">
        <div>
          <h4> Counter Value : {this.state.counter} </h4>
          <button className="w-50" onClick={this.onDecrement}>
            -
          </button>
          <button className="w-50" onClick={this.onIncrement}>
            +
          </button>
        </div>
      </div>
    );
  }
}

export default Counter;

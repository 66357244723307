import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './css/App.css';
import Header from './component/Header/Header';
import About from './component/About/About';
import Todo from './component/Todo';
import Forms from './component/Forms';
import Counter from './component/Counter';
import List from './component/List';

class App extends Component {
  state = {
    title: 'Home',
  };

  render() {
    return (
      <div className="App">
        <div>
          <Router>
            <Header title={this.state.title} />
            <Route exact path="/" component={Todo} />
            <Route exact path="/About" component={About} />
            <Route exact path="/Forms" component={Forms} />
            <Route exact path="/Counter" component={Counter} />
            <Route exact path="/List" component={List} />
          </Router>
        </div>
      </div>
    );
  }
}

export default App;
